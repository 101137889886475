import { emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import {
  ExperienceSectionDescription,
  ExperienceSectionHeader,
  ExperienceSectionTitle,
} from '../../../../../Native/AEP/DiscoveryPortal/Common/ExperienceSection';
import UpdateFocusButton from '../../../../../Native/AEP/DiscoveryPortal/Common/ExperienceUpdateFocus/UpdateFocusButton';

interface Props {
  name: AlreadyTranslatedText;
  id: number;
  description: Maybe<AlreadyTranslatedText>;
}

function Header({ name, id, description }: Props) {
  return (
    <ExperienceSectionHeader>
      <div className="flex flex-1 flex-col-reverse items-start gap-4 sm:flex-row">
        <ExperienceSectionTitle>
          <AlreadyTranslated content={name} />
        </ExperienceSectionTitle>
        <UpdateFocusButton productCollectionId={id} scopeType={'product-collection'} />
      </div>
      {description
        .map((d) => (
          <ExperienceSectionDescription>
            <AlreadyTranslated content={d} />
          </ExperienceSectionDescription>
        ))
        .getOrElse(emptyFragment)}
    </ExperienceSectionHeader>
  );
}

export default observer(Header);

import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import { SkeletonProductCollections } from '../../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Experiences/ProductCollections';
import { T } from '../../../../Translations';
import { useQueryWithDecoder } from '../../../../UseQueryWithDecoder';
import experiencesStore, { isLoaded } from '../../ExperiencesStore';
import { productCollectionsResourceDecoder } from './Decoder';
import ProductCollection from './ProductCollection';

function ProductCollections() {
  const { data, isLoading, error } = useQueryWithDecoder(
    'product-collections',
    experiencesStore.links,
    productCollectionsResourceDecoder,
    { enabled: isLoaded(experiencesStore) },
  );

  if (!isLoaded(experiencesStore) || isLoading) {
    return <SkeletonProductCollections />;
  }

  if (error || !data) {
    console.error('Failed to load product collections:', error);
    return (
      <div className="flex flex-grow flex-col items-center justify-center">
        <Typography>
          <T kind="Your request failed." />
        </Typography>
      </div>
    );
  }

  return fromArrayMaybe(data.payload)
    .map((list) => (
      <>
        {list.toArray().map((productCollection) => (
          <ProductCollection productCollection={productCollection} key={productCollection.id} />
        ))}
      </>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProductCollections);

import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { Decoder } from 'jsonous';
import { decodeOnSelect, queryTaskAsPromise } from '../Appy';
import { Link, Rel } from '../Resource/Types';

/**
 * A custom hook that performs a query with a specified decoder.
 *
 * @template T - The type of the decoded result.
 * @param {Rel} rel - The relationship identifier for the query.
 * @param {ReadonlyArray<Link>} links - An array of links to be used in the query.
 * @param {Decoder<T>} decoder - A decoder function to transform the query result.
 * @param {Omit<UseQueryOptions<string, Error, T | undefined, unknown[]>, 'queryKey' | 'queryFn' | 'select'>} [options] - Optional configuration options for the query.
 * @param {unknown[]} [cacheKey] - An optional array of keys to be used for caching the query result.
 * @returns {UseQueryResult<T | undefined, Error>} The result of the query, which includes the decoded data or an error.
 */
export function useQueryWithDecoder<T>(
  rel: Rel,
  links: ReadonlyArray<Link>,
  decoder: Decoder<T>,
  options?: Omit<UseQueryOptions<string, Error, T | undefined>, 'queryKey' | 'queryFn' | 'select'>,
  cacheKey?: unknown[],
): UseQueryResult<T | undefined, Error> {
  const queryKey = typeof cacheKey === 'undefined' ? [rel] : [rel, ...cacheKey];

  const queryResults = useQuery<string, Error, T | undefined>({
    queryKey,
    queryFn: () => queryTaskAsPromise(rel, links),
    select: decodeOnSelect(decoder),
    ...options,
  });

  return queryResults;
}

import { emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { fromEmpty, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { ProductCollection } from '../../../../Common/Experience/Types';
import DiscoveryContext from '../../../../DiscoveryContext';
import {
  ExperienceSection,
  ExperienceSectionBody,
  ExperienceSectionDescription,
  ExperienceSectionHeader,
} from '../../../Common/ExperienceSection';
import ExperienceUpdateFocus from '../../../Common/ExperienceUpdateFocus';
import EnrollmentStore from '../../../Enrollment/Store';
import { useExperienceFilters } from '../../Filters/FiltersContext';
import NoExperiences from '../Common/NoExperiences';
import TileCarouselExperiences from './TileCarouselExperiences';
import ExpandExperiences from '../ExpandExperiences';

interface Props {
  invitationUuid: string;
  productCollection: ProductCollection;
  enrollmentStore: EnrollmentStore;
}

function TileViewCarouselExperiences({
  productCollection,
  invitationUuid,
  enrollmentStore,
}: Props) {
  const { filterExperienceResources } = useExperienceFilters();

  const filteredExperienceResources = filterExperienceResources(productCollection.experiences);

  return (
    <DiscoveryContext.Provider
      value={{
        kind: productCollection.kind,
        productCollectionId: productCollection.id,
      }}
    >
      <ExperienceSection>
        <ExperienceSectionHeader>
          <ExperienceUpdateFocus
            productCollection={productCollection}
            scopeType="product-collection"
          />
          {productCollection.description
            .map((description) => (
              <ExperienceSectionDescription>
                <AlreadyTranslated content={description} />
              </ExperienceSectionDescription>
            ))
            .getOrElse(emptyFragment)}
        </ExperienceSectionHeader>
        <ExperienceSectionBody>
          <ExpandExperiences displayButtonLabel={productCollection.displayButtonLabel}>
            {fromEmpty(filteredExperienceResources)
              .map(() => (
                <TileCarouselExperiences
                  invitationUuid={invitationUuid}
                  experienceResources={filteredExperienceResources}
                  perPageCount={productCollection.displayPerPageCount}
                  enrollmentStore={enrollmentStore}
                />
              ))
              .getOrElse(() => (
                <NoExperiences searchValue={nothing()} />
              ))}
          </ExpandExperiences>
        </ExperienceSectionBody>
      </ExperienceSection>
    </DiscoveryContext.Provider>
  );
}

export default observer(TileViewCarouselExperiences);

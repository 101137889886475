import { when } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { PropsWithChildren, useState } from 'react';
import ExpandButton from './ExpandButton';

interface Props {
  displayButtonLabel: Maybe<string>;
}

function ExpandExperiences({ displayButtonLabel, children }: PropsWithChildren<Props>) {
  const [expanded, setExpanded] = useState(false);

  return displayButtonLabel
    .andThen(when(!expanded))
    .map((label) => (
      <ExpandButton displayButtonLabel={label} onShowExperiences={() => setExpanded(true)} />
    ))
    .getOrElse(() => <>{children}</>);
}

export default observer(ExpandExperiences);

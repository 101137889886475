import { observer } from 'mobx-react';
import React from 'react';
import DiscoveryContext from '../../../../../Native/AEP/DiscoveryContext';
import {
  ExperienceSection,
  ExperienceSectionBody,
} from '../../../../../Native/AEP/DiscoveryPortal/Common/ExperienceSection';
import { ProductCollection } from '../types';
import ExperienceCard from './ExperienceCard';
import Header from './Header';

interface Props {
  productCollection: ProductCollection;
}

function ProductCollections({ productCollection }: Props) {
  const { name, description, id, experiences } = productCollection;
  return (
    <React.Fragment key={productCollection.id}>
      <div className="bg-white shadow-section">
        <DiscoveryContext.Provider
          value={{
            kind: productCollection.kind,
            productCollectionId: productCollection.id,
          }}
        >
          <ExperienceSection>
            <Header name={name} description={description} id={id} />
            <ExperienceSectionBody>
              {/* <ExpandExperiences displayButtonLabel={productCollection.displayButtonLabel}>
                {fromEmpty(filteredExperienceResources)
                  .map(() => (
                    <TileCarouselExperiences
                      invitationUuid={invitationUuid}
                      experienceResources={filteredExperienceResources}
                      perPageCount={productCollection.displayPerPageCount}
                      enrollmentStore={enrollmentStore}
                    />
                  ))
                  .getOrElse(() => (
                    <NoExperiences searchValue={nothing()} />
                  ))}
              </ExpandExperiences> */}
              <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {experiences.map((experience) => (
                  <ExperienceCard experience={experience} key={experience.payload.id} />
                ))}
              </div>
            </ExperienceSectionBody>
          </ExperienceSection>
        </DiscoveryContext.Provider>
      </div>
    </React.Fragment>
  );
}

export default observer(ProductCollections);
